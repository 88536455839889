import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  tableRoot: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    overflow: "auto",
  },
});
