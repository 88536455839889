import React, { useState, useMemo } from "react";
import tableIcons from "./MaterialTableIcons";
import MaterialTable from "material-table";

import { OpenDialog } from "../App";

import ajax from "../../fetch/ajax";

import "./../../css/table.scss";

export default function Editable({
  data,
  setData,
  add,
  onAdd,
  update,
  del,
  cols,
  values,
  grouping = false,
  ...props
}) {
  const [showError, setShowError] = useState(false);

  return (
    <>
      {showError && (
        <OpenDialog
          title="Errore"
          text="La modifica del database non è stata possibile a causa di un errore. Assicurarsi di aver compilato tutti i campi necessari e che i dati inseriti non siano già presenti nel database."
          clear={setShowError}
        />
      )}
      <MaterialTable
        {...props}
        columns={cols}
        icons={tableIcons}
        data={data}
        localization={{
          body: {
            editRow: { deleteText: "Vuoi davvero cancellare questo record?" },
          },
          toolbar: { searchPlaceholder: "Cerca" },
          pagination: { labelRowsSelect: "righe" },
          grouping: {
            placeholder: "Trascina qui il nome di una colonna per raggruppare",
            groupedBy: "Raggruppato per: ",
          },
        }}
        editable={{
          onRowAdd:
            add &&
            ((newData) =>
              ajax(add, newData)
                .then((j) => {
                  const id_key = Object.keys(j).find((k) => k.includes("id_"));
                  setData([
                    ...data,
                    {
                      ...newData,
                      [id_key]: j[id_key],
                    },
                  ]);
                  console.log(onAdd);
                  if (onAdd) onAdd(j);
                })
                .catch(() => setShowError(true))),
          onRowUpdate:
            update &&
            ((newData, oldData) =>
              ajax(update, newData)
                .then(() =>
                  setData(
                    data.map((v, i) =>
                      i === oldData.tableData.id ? newData : v
                    )
                  )
                )
                .catch(() => setShowError(true))),
          onRowDelete:
            del &&
            ((oldData) =>
              ajax(del, oldData)
                .then(() =>
                  setData(data.filter((v, i) => i !== oldData.tableData.id))
                )
                .catch(() => setShowError(true))),
        }}
        options={{
          grouping: grouping,
          pageSize: 10,
          sorting: true,
          filtering: false,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: window.__CONFIG__.colors.secondary,
            color: "#FFF",
          },
        }}
      />
    </>
  );
}
