import React, { memo } from "react";
import { createPortal } from "react-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default memo(({ title, text, close, field = null, action }) => {
  return createPortal(
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {field || (
          <TextField
            autoFocus
            margin="dense"
            id="mail_dialog_send"
            label="Indirizzo email"
            type="email"
            fullWidth
            multiline
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Annulla
        </Button>
        <Button
          onClick={() =>
            action(document.getElementById("mail_dialog_send").value)
          }
          color="primary"
        >
          Invia
        </Button>
      </DialogActions>
    </Dialog>,
    document.getElementById("dialogRoot")
  );
});
