import React, {
  useMemo,
  memo,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { useNavigate } from "react-router-dom";
import useStyles from "../../css/admin.panel.css";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Context, OpenDialog } from "../App";

import ajax from "../../fetch/ajax";
import download from "../../fetch/download";

import useInvites from "./useInvites";
//import urls from "./urls";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import Ajv from "ajv";

import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LaunchIcon from "@material-ui/icons/Launch";

const ajv = new Ajv({ allErrors: true, verbose: true });

export default function AdminPanel() {
  const classes = useStyles();

  const navigate = useNavigate();
  const ctx = useContext(Context);
  const questionaries = useMemo(() => ctx.questionaries.data, [ctx]);

  const [actionFns, Overlays] = useInvites(questionaries);

  const [logDialog, setLogDialog] = useState(null);

  const actions = useMemo(
    () => ({
      compilazioni: [[null, [["Apri", () => navigate("records")]]]],
      classi: [[null, [["Apri", () => navigate("create")]]]],
      link: actionFns.map(
        (q) =>
          window.__CONFIG__.questionari.includes(q.name) && [
            q.name,
            [
              ["Copia", q.copy, <LibraryAddCheckIcon />],
              ["Invia", q.send, <MailOutlineIcon />],
            ],
          ]
      ),
      sistema: [
        /*    ["DB", [["Apri", () => window.open(urls.dbadmin)]]], */
        [
          "CONFIG",
          [
            [
              "Apri",
              () =>
                setLogDialog({
                  title: "Configurazione",
                  text: (
                    <>
                      <Typography
                        component="span"
                        className={classes.configDescription}
                      >
                        Attenzione! Le modifiche apportate al file di
                        configurazione potrebbero impedire il normale
                        funzionamento dell'app. Procedere con estrema cautela.
                      </Typography>
                      <div style={{ height: "10px" }}></div>{" "}
                      {/*Extremely ugly fix because attempts to put a margin failed*/}
                      <Editor
                        value={window.__CONFIG__}
                        ajv={ajv}
                        onChange={(json) => (window.__CONFIG__NEW = json)}
                        schema={{}}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "center", marginTop: "10px" }}
                        onClick={() => {
                          !window.__CONFIG__NEW
                            ? setLogDialog(null)
                            : ajax("api/changeConfig.php", {
                                mode: process.env.NODE_ENV,
                                config: JSON.stringify(window.__CONFIG__NEW),
                              })
                                .then(() => {
                                  setLogDialog({
                                    title: "Attenzione",
                                    text:
                                      "Per riuscire a vedere le modifiche potresti dover ricaricare la pagina con CTRL + F5",
                                  });
                                })
                                .catch(() =>
                                  setLogDialog({
                                    title: "Errore",
                                    text:
                                      "Impossibile modificare il file di configurazione",
                                  })
                                );
                        }}
                      >
                        Salva
                      </Button>
                    </>
                  ),
                }),
            ],
          ],
        ],
        [
          "LOG",
          [
            [
              "Apri",
              () => {
                ajax("api/logOperations.php")
                  .then((j) =>
                    setLogDialog({
                      title: "Elenco dei log di sistema",
                      text: (
                        <>
                          {j.logs.map((l) => (
                            <div key={l}>
                              {l}
                              <Button
                                onClick={() => {
                                  ajax("api/logOperations.php", { log: l })
                                    .then((jLog64) => {
                                      download(jLog64.log, l);
                                      setLogDialog(null);
                                    })
                                    .catch(() =>
                                      setLogDialog({
                                        title: "Errore",
                                        text: "Download del log fallito",
                                      })
                                    );
                                }}
                              >
                                Scarica
                              </Button>
                              <Button
                                onClick={() => {
                                  window.confirm(
                                    "Vuoi davvero resettare il log?"
                                  ) &&
                                    ajax("api/logOperations.php", {
                                      log: l,
                                      delete: 1,
                                    })
                                      .then(({ log }) => {
                                        setLogDialog({
                                          title: "Successo",
                                          text: "Reset del log eseguito",
                                        });
                                      })
                                      .catch(() =>
                                        setLogDialog({
                                          title: "Errore",
                                          text: "Rimozione del log fallito",
                                        })
                                      );
                                }}
                              >
                                Resetta
                              </Button>
                            </div>
                          ))}
                        </>
                      ),
                    })
                  )
                  .catch(() =>
                    setLogDialog({
                      text: "Errore inatteso durante la richiesta dei log",
                      title: "Errore nel server",
                    })
                  );
              },
            ],
          ],
        ],
        /* [
          "UPLOAD",
          [
            [
              "Apri",
              () => {
                const fileBrowser = window.open("http://localhost:8003");
              },
            ],
          ],
        ], */
      ],
    }),
    [navigate, actionFns]
  );

  // number of items and of requests
  const [numberOfItems, setNumberOfItems] = useState({
    records: "...",
    classi: "...",
    docenti: "...",
  });

  useEffect(() => {
    ajax("api/getNumberOfItems.php")
      .then(setNumberOfItems)
      .catch((err) => console.log("Richiesta dati non riuscita: " + err));
  }, [setNumberOfItems]);

  useEffect(() => {
    if (!window.__KEEP_NAVIGATION__) {
      ajax("common/REST_recordSelect.php")
        .then((j) => {
          if (
            Object.values(j).filter((u) => u.auth_id_prof || u.to_verify_classe)
              .length
          ) {
            navigate("/admin/records");
          }
        })
        .catch((err) => console.log("Richiesta dati non riuscita: " + err));
    }
  }, [navigate, ajax]);

  // return jsx
  return (
    <>
      {logDialog && <OpenDialog {...logDialog} clear={setLogDialog} />}
      {Overlays}
      <div className={classes.cardsRoot + " " + classes.superRoot}>
        <div className={classes.cardsRoot} style={{ marginTop: "auto" }}>
          <AdminCard
            title="COMPILAZIONI"
            text={`${numberOfItems.records} records`}
            actions={actions.compilazioni}
            bg="#4472c4"
          />
          <AdminCard
            title="CLASSI / DOCENTI"
            text={
              <>
                {`${numberOfItems.classi} classi`}
                <br />
                {`${numberOfItems.docenti} docenti`}
              </>
            }
            actions={actions.classi}
            bg="#ed7d31"
          />
        </div>
        <div className={classes.cardsRoot} style={{ marginBottom: "auto" }}>
          <AdminCard
            title="LINK QUESTIONARI"
            actions={actions.link}
            bg="#7030a0"
          />
          <AdminCard title="SISTEMA" actions={actions.sistema} bg="#70ad47" />
        </div>
      </div>
    </>
  );
}

const AdminCard = memo(({ title, text, actions, bg }) => {
  const classes = useStyles();
  const renderedActions = useMemo(
    () =>
      actions.map((a, i) => {
        const [label, buttons] = a;
        return (
          <div key={i} className={classes.lineActionsRoot}>
            {label && (
              <i
                style={
                  title !== "SISTEMA"
                    ? { display: "inline-block", width: "35px" }
                    : { display: "inline-block", width: "60px" }
                }
              >
                {label}
              </i>
            )}
            {buttons.map((b, j) => {
              const [btnText, action, icon] = b;
              return (
                <Button
                  onClick={action || null}
                  color="primary"
                  variant="contained"
                  key={j}
                  startIcon={icon || <LaunchIcon />}
                >
                  {btnText}
                </Button>
              );
            })}
          </div>
        );
      }),
    [actions, classes]
  );
  return (
    <Card className={classes.root} style={{ background: bg }}>
      <CardContent>
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions>
        <div className={classes.actionsRoot}>{renderedActions}</div>
      </CardActions>
    </Card>
  );
});
