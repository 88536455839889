import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarSuperParent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  avatarParent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    left: 0,
    width: "100%",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarDisabled: {
    display: "none",
    transform: "scale(.85,.85)",
    opacity: ".6",
    transition: "transform .3s, opacity .3s",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(.9,.9)",
      opacity: ".9",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pwReset: {
    marginTop: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 230,
  },
  footer: {
    backgroundColor: "red",
    padding: theme.spacing(6),
  },
}));
