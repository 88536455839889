import React, { memo, useCallback, useState, useEffect, useMemo } from "react";
import tableIcons from "../Table/MaterialTableIcons";
import Editable from "../Table/Editable";
import Loading from "../Loading";
import { OpenDialog } from "../App";
import ajax from "../../fetch/ajax";
import download from "../../fetch/download";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import RestoreIcon from "@material-ui/icons/Restore";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

import MultiSelect from "../Table/MultiSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  listItemBig: {
    minWidth: "500px",
  },
}));

const toVerifyStyle = { background: "#ff7c54", padding: "4px" };

export default function Records({ isProf, profTopbar, setProfClassData }) {
  const classes = useStyles();
  const [userData, setUser] = useState(null);
  const [customDialog, setCustomDialog] = useState(null);
  const setDBError = useCallback(
    () =>
      setCustomDialog([
        "Errore",
        "Non è stato possibile recuperare le informazioni dal database, riprovare più tardi.",
      ]),
    [setCustomDialog]
  );

  const showXML = useCallback(
    (rowData, isProf) =>
      ajax(`api${isProf ? "_teacher" : ""}/xmlRequest.php`, rowData)
        .then((j) => {
          if (j.feedback) setCustomDialog(["Richiesta inviata", j.feedback]);
          else if (j.xml) {
            download(j.xml, `quiz_results_${rowData.hash_user}.xml`);
          }
        })
        .catch(setDBError),
    [setDBError]
  );

  // GET DATA AND SHOW NOTIFICATIONS ABOUT NEW AUTHORIZATIONS
  useEffect(() => {
    if (!isProf) window.__KEEP_NAVIGATION__ = 1;
    ajax("common/REST_recordSelect.php")
      .then(async (j) => {
        if (isProf) {
          const newAdminAuths = Object.values(j).filter(
            (u) => u.status == 1 || u.status == -1
          );
          if (newAdminAuths.length) {
            setCustomDialog([
              "L'admin ha risposto alle richieste di visione dei seguenti file",
              <List>
                {newAdminAuths.map((u) => (
                  <ListItem key={u.id_user}>
                    <div style={{ marginRight: "20px" }}>
                      {u.cognome_user} {u.nome_user} ({u.nome_classe})
                    </div>
                    {u.status == 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={() => showXML(u, isProf)}
                      >
                        Scarica xml
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="disabled"
                        size="small"
                        startIcon={<BlockIcon />}
                      >
                        Autorizzazione negata
                      </Button>
                    )}
                  </ListItem>
                ))}
              </List>,
            ]);
          }
        } else {
          const newAdminAuths = Object.values(j).filter((u) => u.auth_id_prof);
          if (newAdminAuths.length) {
            let profData;
            try {
              profData = await ajax("api/REST_profSelect.php");
            } catch (err) {
              console.log("Impossibile ottenere i professori dal server");
            }
            newAdminAuths.forEach((u) => {
              u.auth_id_prof = u.auth_id_prof.split(",");
            });
            function action(el, status, id_user, id_prof) {
              ajax("api/REST_profAuthorize.php", {
                status,
                id_user,
                id_prof,
              })
                .then(() => {
                  const ul = el.closest("ul");
                  el.closest("li").remove();
                  if (!ul.querySelector("li"))
                    document.querySelector(".overlayCloser").click();
                })
                .catch(setDBError);
            }
            setCustomDialog([
              "Nuove richieste di autorizzazione",
              <List>
                {newAdminAuths.map((u) =>
                  u.auth_id_prof.map((id_prof) => {
                    const thisProf = profData.find(
                      (p) => p.id_prof === id_prof
                    );
                    return (
                      <ListItem key={u.id_user + "|" + id_prof}>
                        <div
                          style={{ marginRight: "20px" }}
                          title={`Il docente ${thisProf.cognome_prof} ${
                            thisProf.nome_prof
                          } ha richiesto lo sblocco dell'xml di compilazione del ${
                            window.__CONFIG__.descrizioneQuestionari[
                              u.nome_questionario
                            ] ?? "questionario " + u.nome_questionario
                          } compilato dallo studente ${u.cognome_user} ${
                            u.nome_user
                          } della classe ${u.nome_classe} in data ${
                            u.ricezione_user
                          }.`}
                        >
                          {u.cognome_user} {u.nome_user} ({u.nome_classe}){" "}
                          &#8594; Richiesta da {thisProf.cognome_prof}{" "}
                          {thisProf.nome_prof}
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<CheckIcon />}
                          style={{
                            marginRight: "6px",
                            width: "130px",
                          }}
                          onClick={(evt) => {
                            action(evt.target, +1, u.id_user, id_prof);
                          }}
                        >
                          Concedi
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{
                            width: "100px",
                          }}
                          startIcon={<BlockIcon />}
                          onClick={(evt) => {
                            action(evt.target, -1, u.id_user, id_prof);
                          }}
                        >
                          Nega
                        </Button>
                      </ListItem>
                    );
                  })
                )}
              </List>,
            ]);
          }
        }
        return j;
      })
      .then(
        isProf
          ? (j) => setUser(j.filter((el) => !el.delete_request_user))
          : setUser
      )
      .catch(setDBError);
  }, [setUser, setDBError, showXML, isProf]);

  const [lastAlert, setLastAlert] = useState("");
  useEffect(() => {
    const toVerify = userData
      ?.filter((u) => u.to_verify_classe)
      .filter(
        (u, k, arr) =>
          arr.findIndex((_u) => _u.nome_classe === u.nome_classe) === k
      ); // remove duplicates of the same class to verify
    const title = "Le seguenti classi hanno bisogno di una verifica";
    function action(el, nome_classe, id_nuova_classe, nome_nuova_classe) {
      ajax(
        `api/REST_class${!id_nuova_classe ? "Insert" : "Replace"}.php`,
        !id_nuova_classe
          ? {
              nome_classe,
            }
          : { id_nuova_classe, nome_vecchia_classe: nome_classe }
      )
        .then(() => {
          setUser(
            userData.map((u) =>
              u.nome_classe === nome_classe
                ? {
                    ...u,
                    to_verify_classe: null,
                    nome_classe: nome_nuova_classe || nome_classe,
                  }
                : u
            )
          );
          const ul = el.closest("ul");
          el.closest("li").remove();
          if (!ul.querySelector("li"))
            document.querySelector(".overlayCloser").click();
        })
        .catch(setDBError);
    }
    if (toVerify?.length && !customDialog && lastAlert !== title) {
      setLastAlert(title);
      setCustomDialog([
        title,
        <ClassesDialog
          toVerify={toVerify}
          action={action}
          setDBError={setDBError}
        />,
      ]);
    }
  }, [userData, setUser, customDialog, lastAlert, setDBError]);

  const [classList, setClassList] = useState([]);
  useEffect(() => {
    ajax(
      isProf
        ? "api_teacher/REST_classXProfSelect.php"
        : "api_public/classJson.php"
    )
      .then((j) => {
        setClassList(j);
        setProfClassData && setProfClassData(j);
      })
      .catch(setDBError);
  }, [setClassList, setDBError, isProf, setProfClassData]);

  return (
    <>
      {customDialog && (
        <OpenDialog
          title={customDialog[0]}
          text={customDialog[1]}
          clear={setCustomDialog}
        />
      )}
      {!userData ? (
        <Loading />
      ) : (
        <Editable
          style={{ margin: "10px" }}
          del={`api${isProf ? "_teacher" : ""}/REST_recordDelete.php`}
          update="common/REST_recordUpdate.php"
          title={
            profTopbar || (
              <>
                <h2 style={{ display: "inline" }}>Compilazioni Utenti</h2>{" "}
              </>
            )
          }
          cols={[
            {
              title: "ID",
              field: "id_user",
              editable: "never",
              customSort: (a, b) => a.id_user - b.id_user,
              defaultSort: "desc",
            },
            {
              title: "ID scorm",
              field: "id_scorm",
              editable: "never",
              //render: rowData => rowData.id_scorm ?? ''
            },
            {
              title: "Classe",
              field: "nome_classe",
              render: (rowData) =>
                !!parseInt(rowData.to_verify_classe) ? (
                  <span className="toVerify" style={toVerifyStyle}>
                    {`${rowData.nome_classe} *`}
                  </span>
                ) : (
                  rowData.nome_classe
                ),
              editComponent: (tableData) => (
                <MultiSelect
                  single={true}
                  setChange={(value) => tableData.onChange(value)}
                  selectedValues={tableData.rowData.nome_classe}
                  allValues={classList}
                />
              ),
            },
            { title: "Cognome", field: "cognome_user" },
            { title: "Nome", field: "nome_user" },
            {
              title: "Questionario",
              field: "nome_questionario",
              editable: "never",
            },
            {
              title: "Registrazione",
              field: "registrazione_user",
              editable: "never",
            },
            {
              title: "Ricezione XML",
              field: "ricezione_user",
              editable: "never",
            },
          ]}
          data={userData}
          setData={setUser}
          actions={[
            {
              icon: tableIcons.Visibility,
              tooltip: "Visualizza",
              onClick: (event, rowData) =>
                rowData.ricezione_user
                  ? window.open(
                      `/quizserver/log/quiz_results_${rowData.hash_user}.html`
                    )
                  : setCustomDialog([
                      "",
                      "Il questionario non è stato ancora compilato.",
                    ]),
            },
            (rowData) => {
              const gotAccess = !isProf || (rowData?.status || 0) > 0;
              return {
                icon: !gotAccess ? tableIcons.Lock : SaveIcon,
                tooltip: !gotAccess ? "Richiedi XML" : "Vedi XML",
                onClick: (event, rowData) =>
                  rowData.ricezione_user
                    ? showXML(rowData, isProf)
                    : setCustomDialog([
                        "",
                        "Il questionario non è stato ancora compilato.",
                      ]),
              };
            },
            !isProf &&
              ((rowData) => {
                return (
                  rowData.delete_request_user && {
                    icon: RestoreIcon,
                    tooltip: "Ripristina Record",
                    onClick: (event, rowData) => {
                      ajax("api/REST_recordRestore.php", rowData)
                        .then(() => {
                          setUser(
                            userData.map((u) =>
                              u.id_user === rowData.id_user
                                ? { ...u, delete_request_user: null }
                                : u
                            )
                          );
                          setCustomDialog([
                            "Successo",
                            "Il record è stato ripristinato.",
                          ]);
                        })
                        .catch(setDBError);
                    },
                  }
                );
              }),
          ]}
        />
      )}
    </>
  );
}

const ClassesDialog = memo(({ toVerify, action, setDBError }) => {
  const [classi, setClasses] = useState([]);
  useEffect(() => {
    ajax("api/REST_classSelect.php").then(setClasses).catch(setDBError);
  }, [setClasses, setDBError]);
  return (
    <List>
      {toVerify.map((u) => {
        return (
          <ListItem key={u.id_user}>
            <SelectClass classi={classi} u={u} action={action} />
          </ListItem>
        );
      })}
    </List>
  );
});

const SelectClass = ({ u, classi, action }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(classi.find((c) => c.nome_classe == u.nome_classe)?.id_classe);
  }, [classi, u]);
  const isOriginal = useMemo(
    () =>
      !value ||
      u.nome_classe == classi.find((c) => value == c.id_classe)?.nome_classe,
    [value, classi, u]
  );
  return value ? (
    <>
      <FormControl className={classes.formControl}>
        <Select value={value} onChange={(e) => setValue(e.target.value)}>
          {classi.map((c) => (
            <MenuItem key={c.id_classe} value={c.id_classe}>
              {c.nome_classe}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color={isOriginal ? "primary" : "secondary"}
        size="small"
        startIcon={<CheckIcon />}
        style={{ marginRight: "6px" }}
        onClick={(e) => {
          action(
            e.target,
            u.nome_classe,
            ...(isOriginal
              ? []
              : [value, classi.find((c) => c.id_classe == value).nome_classe])
          );
        }}
      >
        {isOriginal ? "Classe Corretta" : "Rimpiazza con questa"}
      </Button>
    </>
  ) : (
    ""
  );
};
