import React, { useState, useCallback } from "react";
import PromptDialog from "../PromptDialog";
import Alert from "@material-ui/lab/Alert";
import copyToClipboard from "../../clipboard/copy";
import ajax from "../../fetch/ajax";
import urls from "./urls";

export default function useInvites(questionaries) {
  const [dialog, setDialog] = useState(null);
  const [alert, setAlert] = useState(null);
  const setTempAlert = useCallback(
    (p) => {
      setAlert(p);
      setTimeout(() => setAlert(null), 2000);
    },
    [setAlert]
  );

  const actionFns = questionaries.map((q) => ({
    name: q,
    copy() {
      copyToClipboard(urls.questionary + q)
        ? setTempAlert("Indirizzo del questionario copiato nella clipboard")
        : setTempAlert("Errore: copia non riuscita");
    },
    send() {
      setDialog({
        title: `Invia il questionario ${q}`,
        text: `Puoi inviare il messaggio a più destinari separando diversi indirizzi email con un punto e virgola (;)`,
        close: () => setDialog(null),
        action: (mail) => {
          setDialog(null);
          ajax("mail/sendQuestionary.php", { mail, questionary: q })
            .then(() => setTempAlert("Invio email riuscito"))
            .catch((j) =>
              setTempAlert(
                j?.error ||
                  "Errore: invio mail non riuscito. Assicurarsi di aver separato ogni indirizzo email con il carattere punto e virgola."
              )
            );
        },
      });
    },
  }));

  const Overlays = (
    <>
      {dialog && <PromptDialog {...dialog} />}
      {alert && (
        <Alert
          style={{
            position: "fixed",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
          }}
          severity={alert.includes("Error") ? "error" : "success"}
        >
          {alert}
        </Alert>
      )}
    </>
  );

  return [actionFns, Overlays];
}
