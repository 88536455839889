import { useEffect, useState, useReducer, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import endpoint from "./endpoint";

export default function useFetch({ url, start = 0, form }) {
  const navigate = useNavigate();
  const [tries, setTries] = useState(0);
  const [loading, setLoading] = useState(true);
  const [json, setJson] = useState({});
  const [started, setStarted] = useReducer((c) => {
    setJson({});
    return c + 1;
  }, start);
  const loadingAndStarted = useMemo(() => (started ? loading : false), [
    started,
    loading,
  ]);

  useEffect(() => {
    !Object.keys(json).length &&
      started &&
      fetch(endpoint() + url, {
        method: "post",
        body: form ? new FormData(form) : "",
      })
        .then((r) =>
          r.json().then((j) => {
            setJson(j);
            setLoading(false);
          })
        )
        .catch(() => (tries < 3 ? setTries(tries + 1) : navigate("/error")));
  }, [
    tries,
    setTries,
    setLoading,
    setJson,
    navigate,
    url,
    started,
    form,
    json,
  ]);

  return [loadingAndStarted, setStarted, json];
}
