import { memo, useEffect } from "react";

export default memo(({ children, setShowBackButton }) => {
  useEffect(() => {
    setShowBackButton(true);
    return () => {
      setShowBackButton(false);
    };
  });
  return children;
});
