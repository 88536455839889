import React, { useState, useMemo, useCallback, useContext } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { DatePicker } from "@material-ui/pickers";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import useFetch from "../../fetch/useFetch";
import Loading, { Center } from "../Loading";
import Alert from "@material-ui/lab/Alert";

import A from "@material-ui/core/Link";

import "../../css/user.scss";
import useStyles from "../../css/user.css";

import { Context } from "../App";

export default function User() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <UserContent />
    </MuiPickersUtilsProvider>
  );
}

function UserContent() {
  const classes = useStyles();
  const [year, setYear] = useState(new Date("2000-01-01"));

  const [sex, setSex] = useState("f");

  const handleSex = useCallback(
    (evt) => {
      setSex(evt.target.value);
    },
    [setSex]
  );

  const [terms, setTerms] = useState(false);

  const handleTerms = useCallback(() => {
    setTerms(!terms);
  }, [setTerms, terms]);

  const [classe, setClasse] = useState(null);

  const handleClasse = useCallback(
    (evt) => {
      setClasse(evt.target.value);
    },
    [setClasse]
  );

  const ctx = useContext(Context);
  const questionaries = useMemo(() => ctx.questionaries.data, [ctx]);
  const [currQuestionary, setCurrQuestionary] = useState(
    new URLSearchParams(window.location.search).get("q") || ""
  );

  const handleQuestionary = useCallback(
    (evt) => {
      setCurrQuestionary(evt.target.value);
      window.history.replaceState("", "", "/user?q=" + evt.target.value);
    },
    [setCurrQuestionary]
  );

  const [loading, , { error, data }] = useFetch({
    url: "common/getClasses.php",
    start: 1,
  });

  const menuItems = useMemo(
    () =>
      data?.map((c) => (
        <MenuItem key={c} value={c}>
          {c}
        </MenuItem>
      )),
    [data]
  );

  const questionaryItems = useMemo(
    () =>
      questionaries?.map((q) => (
        <MenuItem key={q} value={q}>
          {q}
        </MenuItem>
      )),
    [questionaries]
  );

  if (!window.__CONFIG__.questionari.includes(currQuestionary)) {
    return (
      <Center>
        <Alert severity="error">
          Attenzione: il questionario selezionato non è nell'elenco
        </Alert>
      </Center>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Center>
        <Alert severity="error">Errore: {error}</Alert>
      </Center>
    );
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GroupIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Accesso Utente ( {currQuestionary} )
        </Typography>
        {window.__CONFIG__?.descrizioneQuestionari?.[currQuestionary] && (
          <Typography component="p" variant="body2">
            {window.__CONFIG__.descrizioneQuestionari[currQuestionary]}
          </Typography>
        )}
        <form
          className={classes.form}
          method="post"
          action="/api_user/createUser.php"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="nome_user"
                variant="outlined"
                required
                fullWidth
                label="Nome"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Cognome"
                name="cognome_user"
                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                style={{
                  width: "100%",
                }}
              >
                <FormLabel component="legend">Genere</FormLabel>
                <RadioGroup
                  required
                  aria-label="genere"
                  name="genere_user"
                  value={sex}
                  onChange={handleSex}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    value="f"
                    control={<Radio />}
                    label="Femminile"
                  />
                  <FormControlLabel
                    value="m"
                    control={<Radio />}
                    label="Maschile"
                  />
                  <FormControlLabel
                    value="x"
                    control={<Radio />}
                    label="Altro"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <div className={classes.twoSelectsRoot}>
            <div style={{ marginTop: "9px" }}>
              <DatePicker
                name="eta_user"
                required
                views={["year"]}
                maxDate={new Date()}
                minDate={new Date("1940-01-01")}
                label="Anno di nascita"
                value={year}
                onYearChange={setYear}
                onChange={setYear}
                className={classes.datePicker}
              />
            </div>

            <FormControl required className={classes.formControl}>
              <InputLabel>Seleziona la classe</InputLabel>
              <Select
                name="nome_classe"
                value={classe || ""} // a fix to avoid a stupid warning by material ui
                onChange={handleClasse}
                className={classes.selectEmpty}
              >
                {menuItems}
              </Select>
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              style={{ display: "none" }}
            >
              <InputLabel>Selezione questionario</InputLabel>
              <Select
                name="nome_questionario"
                value={currQuestionary || ""} // a fix to avoid a stupid warning by material ui
                onChange={handleQuestionary}
                className={classes.selectEmpty}
              >
                {questionaryItems}
              </Select>
            </FormControl>
          </div>

          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={terms}
                  onChange={handleTerms}
                  name="terms"
                />
              }
              label={
                <div>
                  Accetto i termini e le condizioni.
                  {window.__CONFIG__?.policy && (
                    <A
                      target="_blank"
                      rel="noopener noreferrer"
                      href={window.__CONFIG__.policy}
                    >
                      Vai alle policy.
                    </A>
                  )}
                </div>
              }
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Conferma e Accedi al Questionario {currQuestionary}
          </Button>
        </form>
      </div>
    </Container>
  );
}
