import endpoint from "./endpoint";

export default async (url, params = {}) =>
  fetch(endpoint() + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body:
      typeof params === "string"
        ? params
        : new URLSearchParams({
            token: document.cookie.match(/token=([^;]+);?/)?.[1],
            ...params,
          }),
  }).then(async (r) => {
    if (!r.ok) throw new Error("Response is not OK: " + JSON.stringify(r));
    return await r.json();
  });
