import { getPathname } from "../jsx/App";

const base = process.env.NODE_ENV === "production" ? "../" : "";

export default () =>
  base +
  (getPathname() || "/")
    .match(/\//g)
    .slice(1)
    .map((_) => "../")
    .join("");
