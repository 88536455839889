import React, {
  memo,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import deleteCookies from "../cookie/delete";
import { Context } from "./App";

import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { OpenDialog } from "./App";

export default memo(({ children, role }) => {
  const navigate = useNavigate();
  const [clicks, setClicks] = useReducer((c) => c + 1, 0);
  const [error, setError] = useState(null);

  const ctx = useContext(Context);
  useEffect(() => {
    if (clicks)
      deleteCookies()
        .then((r) => {
          if (r.status !== 200) throw new Error();
          ctx.setRenderFunctions([null, null]);
          navigate(`/${role}_login`);
        })
        .catch(() => setError(true));
  }, [navigate, ctx, clicks, setError, role]);
  return (
    <>
      {error && (
        <OpenDialog
          text="Errore nel server durante il logout"
          title="Errore"
          clear={setError}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        id="logOutButton"
        onClick={setClicks}
      >
        {children}
      </Button>
    </>
  );
});
