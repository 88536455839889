import React from "react";
import { Center } from "./Loading";
import Button from "@material-ui/core/Button";

export default function Error() {
  return (
    <Center>
      <div style={{ padding: "10px", fontSize: "1.2rem" }}>
        C'è stato un errore nel server, riprova più tardi.
        <Button onClick={window.history.back()}>Torna indietro</Button>
      </div>
    </Center>
  );
}
