import React, { useState, useMemo, useEffect, useCallback } from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Editable from "../Table/Editable";
import useStyles from "../../css/admin.create.css";
import ajax from "../../fetch/ajax";
import { OpenDialog } from "../App";
import Loading from "../Loading";

import MultiSelect from "../Table/MultiSelect";

const style = { flexGrow: 1, margin: "10px" },
  styleClassi = { ...style, flexBasis: "30%", minWidth: "500px" },
  styleDocenti = { ...style, flexBasis: "60%" };

export default function Create() {
  const classes = useStyles();
  const [classData, setClass] = useState(null);
  const [profData, setProf] = useState(null);
  const classDataFiltered = useMemo(
    () => classData?.filter((c) => !c.to_verify_classe),
    [classData]
  );
  const classDataArray = useMemo(
    () => classDataFiltered?.map((c) => c.nome_classe),
    [classDataFiltered]
  );
  const profDataWithUpdatedClass = useMemo(
    () =>
      profData?.map((p) => ({
        // TODO FIX: SE FA UPDATE DI NOME CLASSE, QUI NON VIENE MOSTRATO
        ...p,
        classi: classDataArray
          ?.filter((c) => p?.classi?.includes(c))
          .join(", "),
      })),
    [profData, classDataArray]
  );

  const [customDialog, setCustomDialog] = useState(null);
  const setDBError = useCallback(
    () =>
      setCustomDialog([
        "Errore",
        "Non è stato possibile recuperare le informazioni dal database, riprovare più tardi.",
      ]),
    [setCustomDialog]
  );
  const showMailSent = useCallback(
    (j) =>
      setCustomDialog(
        j.mailSuccess
          ? ["Successo", "Email inviata correttamente"]
          : ["Errore", "Non è stato possibile inviare la mail"]
      ),
    [setCustomDialog]
  );

  useEffect(() => {
    ajax("api/REST_classSelect.php").then(setClass).catch(setDBError);
    ajax("api/REST_profSelect.php").then(setProf).catch(setDBError);
  }, [setDBError]);

  return (
    <>
      {customDialog && (
        <OpenDialog
          title={customDialog[0]}
          text={customDialog[1]}
          clear={setCustomDialog}
        />
      )}
      {!classData || !profData ? (
        <Loading />
      ) : (
        <div className={classes.tableRoot}>
          <Editable
            data={classDataFiltered}
            setData={setClass}
            add="api/REST_classInsert.php"
            update="api/REST_classUpdate.php"
            del="api/REST_classDelete.php"
            title="Classi"
            cols={[
              {
                title: "ID",
                field: "id_classe",
                editable: "never",
                customSort: (a, b) => a.id_classe - b.id_classe,
                defaultSort: "desc",
              },
              { title: "Nome", field: "nome_classe" },
            ]}
            style={styleClassi}
          />
          <Editable
            data={profDataWithUpdatedClass}
            setData={setProf}
            add="api/REST_profInsert.php"
            onAdd={showMailSent}
            update="api/REST_profUpdate.php"
            del="api/REST_profDelete.php"
            title="Docenti"
            cols={[
              {
                title: "ID",
                field: "id_prof",
                editable: "never",
                customSort: (a, b) => a.id_prof - b.id_prof,
                defaultSort: "desc",
              },
              { title: "Username", field: "username_prof" },
              { title: "Nome", field: "nome_prof" },
              { title: "Cognome", field: "cognome_prof" },
              {
                title: "Classi",
                field: "classi",
                editComponent: (tableData) => (
                  <MultiSelect
                    setChange={(values) =>
                      tableData.onChange(values.join(", "))
                    }
                    selectedValues={tableData.rowData.classi
                      ?.split(", ")
                      .filter((c) => classDataArray.includes(c))}
                    allValues={classDataArray}
                  />
                ),
              },
            ]}
            style={styleDocenti}
            actions={[
              {
                icon: MailOutlineIcon,
                tooltip: "Rimanda Mail",
                onClick: (event, rowData) => {
                  ajax("api/REST_profReset.php", rowData).then((j) =>
                    showMailSent(j)
                  );
                },
              },
            ]}
          />
        </div>
      )}
    </>
  );
}
