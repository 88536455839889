import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  cardsRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  superRoot: {
    height: "100%",
  },
  root: {
    minWidth: 275,
    height: 205,
    margin: "20px",
    textAlign: "center",
    "& p, & h3, & button span, & div": {
      fontWeight: "bold",
      color: "white",
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actionsRoot: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& button": {
      margin: "2px 0 2px 5px",
    },
  },
  lineActionsRoot: {
    marginLeft: "8px",
    paddingBottom: "6px",
    marginTop: "-7px",
  },
  configDescription: {},
});
