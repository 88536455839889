import React, { useState } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export default function useTabs({ tabs, ...props }) {
  const [selected, setSelected] = useState(0);
  function handleChange(evt, index) {
    setSelected(index);
  }
  return [
    selected,
    () => (
      <Tabs
        value={selected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        {...(props || {})}
      >
        {tabs.map((tab) => (
          <Tab key={tab.label} {...tab} />
        ))}
      </Tabs>
    ),
  ];
}
