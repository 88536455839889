/* export default process.env.NODE_ENV !== "production"
  ? {
      questionary: "https://localhost:3000/user?q=",
      dbadmin: "http://localhost:5000",
    }
  : {
      questionary: "https://localhost:3000/user?q=",
      dbadmin: "http://localhost:5000",
    }; */

export default {
  questionary: `https://${window.location.host}/user?q=`,
};
