import React, { useState } from "react";
import Editable from "./Editable";

export default function useEditable(props) {
  const [data, setData] = useState([]);
  return [
    data,
    setData,
    () => <Editable data={data} setData={setData} {...props} />,
  ];
}
