import React, { useContext, useState, memo, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Login from "./Login";
import Error from "./Error";
import Admin from "./Admin/Admin";
import Teacher from "./Teacher/Teacher";
import User from "./User/User";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useFetch from "../fetch/useFetch";
import Loading, { Center } from "./Loading";

import "./../css/hf.scss";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { AvatarSection } from "./Login";
import Build from "@material-ui/icons/Build";
import Equalizer from "@material-ui/icons/Equalizer";

import Link from "@material-ui/core/Link";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: window.__CONFIG__.colors.primary,
    },
    secondary: {
      main: window.__CONFIG__.colors.secondary,
    },
  },
});

const Context = React.createContext([]),
  { Provider } = Context;

export { Context };

export function useRenderFunctions(payload) {
  const ctx = useContext(Context);
  useEffect(() => {
    // this must be done in useEffect to not break React render cycle
    ctx.setRenderFunctions(payload);
  }, [ctx, payload]);
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  );
}

let location;
export function getPathname() {
  return location.pathname;
}

const Main = memo(() => {
  location = useLocation();
  const navigate = useNavigate();
  const [loading, , questionaries] = useFetch({
    url: "common/getQuestionaries.php",
    start: 1,
  });
  const [[renderHeader, renderFooter], setRenderFunctions] = useState([]);
  if (loading) return <Loading />;
  if (questionaries.error) return <Navigate to="/error" />;
  if (questionaries.success)
    return (
      <>
        <Provider value={{ setRenderFunctions, questionaries }}>
          <div
            style={{
              width: "100%",
              margin: "0",
              flex: 1,
              background: window.__CONFIG__.main.bg,
            }}
          >
            <CssBaseline />
            <HeaderFooter
              renderFunction={renderHeader}
              background={window.__CONFIG__.header.bg}
            >
              <span
                className="hfConfigurable"
                style={{ transform: "translate(10%, 0) scale(1.2)" }}
                dangerouslySetInnerHTML={{
                  __html: window.__CONFIG__.header.left,
                }}
              />
              <span
                className="hfConfigurable"
                dangerouslySetInnerHTML={{
                  __html: window.__CONFIG__.header.right,
                }}
              />
            </HeaderFooter>
            <Routes>
              <Route
                path="/"
                element={
                  <Center>
                    <Link
                      href="/admin_login"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin_login");
                      }}
                    >
                      <AvatarSection
                        icon={<Build />}
                        text="Area Admin"
                        style={{ margin: "5px" }}
                      />
                    </Link>
                    <Link
                      href="/teacher_login"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/teacher_login");
                      }}
                    >
                      <AvatarSection
                        icon={<Equalizer />}
                        text="Sezione Docenti"
                        style={{ margin: "5px" }}
                      />
                    </Link>
                  </Center>
                }
              />
              <Route path="/admin_login" element={<Login isAdmin />} />
              <Route path="/teacher_login" element={<Login />} />
              <Route path="/error" element={<Error />} />
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/teacher/*" element={<Teacher />} />
              <Route path="/user/*" element={<User />} />
            </Routes>
          </div>
          <div id="dialogRoot"></div>
          <HeaderFooter
            renderFunction={renderFooter}
            background={window.__CONFIG__.footer.bg}
          >
            <span
              className="hfConfigurable"
              dangerouslySetInnerHTML={{
                __html: window.__CONFIG__.footer.left,
              }}
            />
            <span
              className="hfConfigurable"
              dangerouslySetInnerHTML={{
                __html: window.__CONFIG__.footer.right,
              }}
            />
          </HeaderFooter>
        </Provider>
      </>
    );
});

const HeaderFooter = memo(({ renderFunction, children, background }) => {
  return (
    <AppBar position="relative">
      <Toolbar>
        <Typography
          variant="subtitle2"
          color="inherit"
          noWrap
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {renderFunction ? renderFunction() : children}
        </Typography>
      </Toolbar>
    </AppBar>
  );
});

export const OpenDialog = memo(({ title, text, clear }) => {
  return createPortal(
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="overlayCloser"
          onClick={() => clear(null)}
          color="primary"
        >
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>,
    document.getElementById("dialogRoot")
  );
});
