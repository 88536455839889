import React, { useEffect, useMemo, useState } from "react";

import { Navigate, Routes, Route } from "react-router-dom";

import Loading from "../Loading";
import useFetch from "../../fetch/useFetch";

import { useRenderFunctions } from "../App";
import Logout from "../Logout";

import Section from "../Section";
import Records from "./Records";
import Create from "./Create";
import AdminPanel from "./AdminPanel";

import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function Admin() {
  const [loading, , { role, token }] = useFetch({
    url: "api/checkLoginStatus.php",
    start: 1,
  });

  const navigate = useNavigate();
  const [showBackButton, setShowBackButton] = useState(false);

  useRenderFunctions(
    useMemo(
      () =>
        token
          ? [
              () => (
                <>
                  {showBackButton && (
                    <div className="hfConfigurable">
                      <Button
                        variant="contained"
                        color="default"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate("/admin")}
                      >
                        Indietro
                      </Button>
                    </div>
                  )}
                  <div className="hfConfigurable">Area Admin</div>
                  <span
                    className="hfConfigurable"
                    dangerouslySetInnerHTML={{
                      __html: window.__CONFIG__.header.right,
                    }}
                  />
                </>
              ),
              () => (
                <>
                  <Logout role="admin">Esci</Logout>{" "}
                  <span
                    className="hfConfigurable"
                    dangerouslySetInnerHTML={{
                      __html: window.__CONFIG__.footer.right,
                    }}
                  />
                </>
              ),
            ]
          : [null, null],
      [token, showBackButton, navigate]
    )
  );

  if (loading) return <Loading />;
  if (role !== "admin") return <Navigate to="/" replace />;
  return (
    <Routes>
      <Route path="/" element={<AdminPanel />} />
      <Route
        path="/records"
        element={
          <Section setShowBackButton={setShowBackButton}>
            <Records />
          </Section>
        }
      />
      <Route
        path="/create"
        element={
          <Section setShowBackButton={setShowBackButton}>
            <Create />
          </Section>
        }
      />
    </Routes>
  );
}
