import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Build from "@material-ui/icons/Build";
import Equalizer from "@material-ui/icons/Equalizer";
import Typography from "@material-ui/core/Typography";

import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";

import "./../css/admin.scss";
import { useNavigate, Navigate } from "react-router-dom";

import Loading from "./Loading";
import useFetch from "../fetch/useFetch";

import useStyles from "../css/admin.css";

import { useRenderFunctions, OpenDialog } from "./App";

export default function Login({ isAdmin }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const ref = useRef();

  const [customDialog, setCustomDialog] = useState(null);

  const isFirstTeacherLogin =
    !isAdmin && new URLSearchParams(window.location.search).get("hash");

  const [loading, , { isFirstAccess, role }] = useFetch({
    url: "api/checkLoginStatus.php",
    start: 1,
  });

  const [
    loadingAdminCreate,
    startAdminCreate,
    { errorAdminCreate, successAdminCreate },
  ] = useFetch({
    url: "api/createAdminEnv.php",
    form: ref.current,
  });

  const [
    loadingAdminLogin,
    startAdminLogin,
    { errorAdminLogin, successAdminLogin },
  ] = useFetch({
    url: "api/adminLogin.php",
    form: ref.current,
  });

  const [
    loadingProfCreate,
    startProfCreate,
    { errorProfCreate, successProfCreate },
  ] = useFetch({
    url: "api_teacher/createProf.php",
    form: ref.current,
  });

  const [
    loadingProfLogin,
    startProfLogin,
    { errorProfLogin, successProfLogin },
  ] = useFetch({
    url: "api_teacher/profLogin.php",
    form: ref.current,
  });

  useEffect(() => {
    (successAdminCreate || successAdminLogin) && navigate("../admin");
  }, [navigate, successAdminCreate, successAdminLogin]);

  useEffect(() => {
    (successProfCreate || successProfLogin) && navigate("../teacher");
  }, [navigate, successProfCreate, successProfLogin]);

  useRenderFunctions(useMemo(() => [null, null], []));

  const [emailInput, setEmailInput] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);

  const handleSubmit = useCallback(
    (evt) => {
      if (
        document.getElementById("username") &&
        !/[^@]+@[^@.]+\.[^@.]+/.test(emailInput)
      ) {
        evt.preventDefault();
        setEmailInvalid(true);
        return;
      }
      function repInvalid(rep) {
        if (rep.value !== document.getElementById("password").value) {
          rep.setCustomValidity(
            "Attenzione: non hai ripetuto correttamente la password"
          );
          rep.closest("form").reportValidity();
          const repReset = [() => rep.setCustomValidity(""), { once: true }];
          rep.addEventListener("input", ...repReset);
          document.addEventListener("click", ...repReset);
          return true;
        }
      }
      evt.preventDefault();
      const rep = document.getElementById("repeatpassword");
      if (isAdmin) {
        if (rep) {
          if (!repInvalid(rep)) {
            startAdminCreate();
          }
        } else {
          startAdminLogin();
        }
      } else {
        if (rep) {
          if (!repInvalid(rep)) {
            startProfCreate();
          }
        } else {
          startProfLogin();
        }
      }
    },
    [
      isAdmin,
      startAdminCreate,
      startAdminLogin,
      startProfCreate,
      startProfLogin,
      emailInput,
    ]
  );

  const handleAvatarClick = useCallback(
    (evt) => {
      const avatarDisabled = evt.target.closest("." + classes.avatarDisabled);
      if (avatarDisabled) {
        ref.current.reset();
        navigate(isAdmin ? "/teacher_login" : "/");
      }
    },
    [navigate, isAdmin, classes.avatarDisabled, ref]
  );

  if (
    loading ||
    loadingAdminCreate ||
    loadingAdminLogin ||
    loadingProfCreate ||
    loadingProfLogin
  )
    return <Loading />;
  if (role && !isFirstTeacherLogin)
    return (
      <Navigate to={`/${role === "admin" ? "admin" : "teacher"}`} replace />
    );
  return (
    <Container component="main" maxWidth="xs">
      {customDialog && (
        <OpenDialog
          title={customDialog[0]}
          text={customDialog[1]}
          clear={setCustomDialog}
        />
      )}
      <div className={classes.paper}>
        <div className={classes.avatarSuperParent} onClick={handleAvatarClick}>
          <AvatarSection
            icon={<Build />}
            text="Area Admin"
            disabled={!isAdmin}
          />
          {!isFirstAccess ? (
            <AvatarSection
              icon={<Equalizer />}
              text="Sezione Docenti"
              disabled={isAdmin}
            />
          ) : (
            !isAdmin && (
              <Alert severity="error">
                ATTENZIONE: prima di entrare come docente è necessario che vi
                sia stato il primo accesso da parte dell'admin
              </Alert>
            )
          )}
        </div>

        {isFirstTeacherLogin && (
          <p>
            Benvenuto{" "}
            {new URLSearchParams(window.location.search).get("mail") ||
              "professore"}
            ,
            <br />
            Procedi all'impostazione/reset della password
          </p>
        )}

        <form ref={ref} className={classes.form}>
          <Grid container spacing={2}>
            {!isFirstTeacherLogin && (
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Email"
                  value={emailInput}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                    setEmailInvalid(false);
                  }}
                  autoFocus
                  error={emailInvalid ? true : false}
                  helperText={emailInvalid ? "La mail non è valida" : null}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                type="password"
              />
            </Grid>

            {(!!isFirstAccess || !!isFirstTeacherLogin) && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="repeatpassword"
                  label="Ripeti Password"
                  name="repeatpassword"
                  autoComplete="password"
                  type="password"
                />
              </Grid>
            )}

            {isFirstTeacherLogin && (
              <input type="hidden" name="token" value={isFirstTeacherLogin} />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              {isFirstAccess ? "Registrati e Accedi" : "Conferma e Accedi"}
            </Button>

            {!isFirstAccess && !isFirstTeacherLogin && (
              <Button
                fullWidth
                variant="text"
                color="primary"
                className={classes.pwReset}
                onClick={() =>
                  setCustomDialog([
                    "Cambio password",
                    <>
                      Per cambiare la password,{" "}
                      {isAdmin ? (
                        <Link href="mailto:helpdesk@laterallearning.it">
                          manda una mail all'assistenza
                        </Link>
                      ) : (
                        <Link href="api_teacher/getAdminMail.php">
                          contatta l'amministratore del sistema
                        </Link>
                      )}
                    </>,
                  ])
                }
              >
                {"Non ricordo la password"}
              </Button>
            )}
          </Grid>

          {(errorAdminLogin ||
            errorAdminCreate ||
            errorProfLogin ||
            errorProfCreate) && (
            <Grid item xs={12}>
              <Alert severity="error">
                Errore:{" "}
                {errorAdminLogin ||
                  errorAdminCreate ||
                  errorProfLogin ||
                  errorProfCreate}
              </Alert>
            </Grid>
          )}
        </form>
      </div>
    </Container>
  );
}

export function AvatarSection({ icon, text, disabled, style }) {
  const classes = useStyles();
  return (
    <div
      className={
        classes.avatarParent + (disabled ? " " + classes.avatarDisabled : "")
      }
    >
      <Avatar className={classes.avatar}>{icon}</Avatar>
      <Typography component="h1" variant="h5" style={style}>
        {text}
      </Typography>
    </div>
  );
}
