import React, { useState, memo, useCallback } from "react";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const style = { width: "160px" };

export default memo(({ allValues, selectedValues, setChange, single }) => {
  const [values, setValues] = useState(selectedValues || (single ? "" : []));

  const change = useCallback(
    (evt) => {
      setValues(evt.target.value);
      setChange(evt.target.value);
    },
    [setValues, setChange]
  );

  return (
    <Select
      multiple={!single}
      value={values}
      onChange={change}
      input={<Input />}
      style={style}
    >
      {allValues.map((v) => (
        <MenuItem key={v} value={v}>
          {v}
        </MenuItem>
      ))}
    </Select>
  );
});
