import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  centerRoot: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Center>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </Center>
  );
}

export function Center({ children }) {
  const classes = useStyles();
  return <div className={classes.centerRoot}>{children}</div>;
}
